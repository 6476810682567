<template>
  <div>
    <leave-type />
    <leave-balance />
  </div>
</template>
<script>
export default {
  components: {
    'leave-type': () => import('./Settings/LeaveTypes.vue'),
    'leave-balance': () => import('./Settings/CustomizeBalance.vue')
  }
}
</script>
